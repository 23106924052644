import React from "react"

export default function HeroButtonGroupComponent() {
  return (
    <div className="flex flex-col flex-wrap items-center justify-center gap-6 md:flex-row">
      {/*<!-- Component: Large sized primary basic button group --> */}
      <div className="inline-flex overflow-hidden rounded">
        <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
          <span>Years</span>
        </button>
        <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
          <span>Months</span>
        </button>
        <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
          <span>Days</span>
        </button>
      </div>
      {/*<!-- End Large sized primary basic button group --> */}
      {/*<!-- Component: Large sized secondary basic button group --> */}
      <div className="inline-flex overflow-hidden rounded">
        <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
          <span>Years</span>
        </button>
        <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
          <span>Months</span>
        </button>
        <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
          <span>Days</span>
        </button>
      </div>
      {/*<!-- End Large sized secondary basic button group --> */}
      {/*<!-- Component: Large sized basic outline button group --> */}
      <div className="inline-flex divide-x divide-emerald-500 overflow-hidden rounded border border-emerald-500 disabled:border-emerald-300">
        <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
          <span>Years</span>
        </button>
        <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
          <span>Months</span>
        </button>
        <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
          <span>Days</span>
        </button>
      </div>
      {/*<!-- End Large sized basic outline button group --> */}
    </div>
  )
}
