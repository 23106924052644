import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ButtonGroupsAllLinkPreview(props) {
  const {
    ButtonGroupsRoundedLgLinkBasic,
    ButtonGroupsRoundedBaseLinkBasic,
    ButtonGroupsRoundedSmLinkBasic,
  } = props

  return (
    <>
      <div className="flex w-full flex-col gap-20 md:w-auto">
        <div className="inline-flex flex-col gap-8">
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedLgLinkBasic}
              componentName="ButtonGroupsRoundedLgLinkBasic"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Large sized basic link button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
                  <span>Large</span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
                  <span>Large</span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
                  <span>Large</span>
                </button>
              </div>
              {/*<!-- End Large sized basic link button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedBaseLinkBasic}
              componentName="ButtonGroupsRoundedBaseLinkBasic"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Base sized basic link button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
                  <span>Base</span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
                  <span>Base</span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
                  <span>Base</span>
                </button>
              </div>
              {/*<!-- End Base sized basic link button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedSmLinkBasic}
              componentName="ButtonGroupsRoundedSmLinkBasic"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Sm sized basic link button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
                  <span>Small</span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
                  <span>Small</span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
                  <span>Small</span>
                </button>
              </div>
              {/*<!-- End Sm sized basic link button group --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
