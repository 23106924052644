import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ButtonGroupsAllPrimaryPreview(props) {
  const {
    ButtonGroupsRoundedLgPrimaryBasic,
    ButtonGroupsRoundedBasePrimaryBasic,
    ButtonGroupsRoundedSmPrimaryBasic,
  } = props

  const {
    ButtonGroupsRoundedLgPrimaryElevated,
    ButtonGroupsRoundedBasePrimaryElevated,
    ButtonGroupsRoundedSmPrimaryElevated,
  } = props

  const {
    ButtonGroupsRoundedLgPrimaryLeadingIcon,
    ButtonGroupsRoundedBasePrimaryLeadingIcon,
    ButtonGroupsRoundedSmPrimaryLeadingIcon,
  } = props

  const {
    ButtonGroupsRoundedLgPrimaryTrailingIcon,
    ButtonGroupsRoundedBasePrimaryTrailingIcon,
    ButtonGroupsRoundedSmPrimaryTrailingIcon,
  } = props

  const {
    ButtonGroupsRoundedLgPrimaryDisabled,
    ButtonGroupsRoundedBasePrimaryDisabled,
    ButtonGroupsRoundedSmPrimaryDisabled,
  } = props

  const {
    ButtonGroupsRoundedLgPrimaryIcon,
    ButtonGroupsRoundedBasePrimaryIcon,
    ButtonGroupsRoundedSmPrimaryIcon,
  } = props

  return (
    <>
      <div className="flex w-full flex-col gap-20 md:w-auto">
        <div className="inline-flex flex-col gap-8">
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedLgPrimaryBasic}
              componentName="ButtonGroupsRoundedLgPrimaryBasic"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Large sized primary basic button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Large</span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Large</span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Large</span>
                </button>
              </div>
              {/*<!-- End Large sized primary basic button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedBasePrimaryBasic}
              componentName="ButtonGroupsRoundedBasePrimaryBasic"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Base sized primary basic button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Base</span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Base</span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Base</span>
                </button>
              </div>
              {/*<!-- End Base sized primary basic button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedSmPrimaryBasic}
              componentName="ButtonGroupsRoundedSmPrimaryBasic"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Sm sized primary basic button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Small</span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Small</span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Small</span>
                </button>
              </div>
              {/*<!-- End Sm sized primary basic button group --> */}
            </CopyComponent>
          </div>
        </div>
        <div className="inline-flex flex-col gap-8">
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedLgPrimaryElevated}
              componentName="ButtonGroupsRoundedLgPrimaryElevated"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Large sized primary elevated button group --> */}
              <div className="inline-flex overflow-hidden rounded shadow-lg shadow-emerald-200 transition duration-300 hover:shadow-md hover:shadow-emerald-200 focus:shadow-md focus:shadow-emerald-200">
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300">
                  <span>Elevated</span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300">
                  <span>Elevated</span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300">
                  <span>Elevated</span>
                </button>
              </div>
              {/*<!-- End Large sized primary elevated button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedBasePrimaryElevated}
              componentName="ButtonGroupsRoundedBasePrimaryElevated"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Base sized primary elevated button group --> */}
              <div className="inline-flex overflow-hidden rounded shadow-lg shadow-emerald-200 transition duration-300 hover:shadow-md hover:shadow-emerald-200 focus:shadow-md focus:shadow-emerald-200">
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300">
                  <span>Elevated</span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300">
                  <span>Elevated</span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300">
                  <span>Elevated</span>
                </button>
              </div>
              {/*<!-- End Base sized primary elevated button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedSmPrimaryElevated}
              componentName="ButtonGroupsRoundedSmPrimaryElevated"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Sm sized primary elevated button group --> */}
              <div className="inline-flex overflow-hidden rounded shadow-lg shadow-emerald-200 transition duration-300 hover:shadow-md hover:shadow-emerald-200 focus:shadow-md focus:shadow-emerald-200">
                <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300">
                  <span>Elevated</span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300">
                  <span>Elevated</span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300">
                  <span>Elevated</span>
                </button>
              </div>
              {/*<!-- End Sm sized primary elevated button group --> */}
            </CopyComponent>
          </div>
        </div>
        <div className="inline-flex flex-col gap-8">
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedLgPrimaryLeadingIcon}
              componentName="ButtonGroupsRoundedLgPrimaryLeadingIcon"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Large sized primary button group with leading icon and badge --> */}
              <div className="inline-flex divide-x divide-emerald-100 overflow-hidden rounded">
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="order-2">Badge</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span className="absolute -top-2 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                      3<span className="sr-only"> new emails</span>
                    </span>
                  </span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="order-2">Lead</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="order-2">Lead</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z" />
                    </svg>
                  </span>
                </button>
              </div>
              {/*<!-- End Large sized primary button group with leading icon and badge --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedBasePrimaryLeadingIcon}
              componentName="ButtonGroupsRoundedBasePrimaryLeadingIcon"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Base sized primary button group with leading icon and badge --> */}
              <div className="inline-flex divide-x divide-emerald-100 overflow-hidden rounded">
                <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="order-2">Badge</span>
                  <span className="relative only:-mx-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span className="absolute -top-2 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                      {" "}
                      9<span className="sr-only"> new emails</span>{" "}
                    </span>
                  </span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="order-2">Lead</span>
                  <span className="relative only:-mx-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="order-2">Lead</span>
                  <span className="relative only:-mx-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z" />
                    </svg>
                  </span>
                </button>
              </div>
              {/*<!-- End Base sized primary button group with leading icon and badge --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedSmPrimaryLeadingIcon}
              componentName="ButtonGroupsRoundedSmPrimaryLeadingIcon"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Sm sized primary button group with leading icon and badge --> */}
              <div className="inline-flex divide-x divide-emerald-100 overflow-hidden rounded">
                <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="order-2">Badge</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span className="absolute -right-0.5 -top-0.5 rounded-full bg-pink-500 p-1">
                      <span className="sr-only">8 new emails</span>
                    </span>
                  </span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="order-2">Lead</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-4 w-4"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="order-2">Lead</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-4 w-4"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z" />
                    </svg>
                  </span>
                </button>
              </div>
              {/*<!-- End Sm sized primary button group with leading icon and badge --> */}
            </CopyComponent>
          </div>
        </div>
        <div className="inline-flex flex-col gap-8">
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedLgPrimaryTrailingIcon}
              componentName="ButtonGroupsRoundedLgPrimaryTrailingIcon"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Large sized primary button group with trailing icon and badge --> */}
              <div className="inline-flex divide-x divide-emerald-100 overflow-hidden rounded">
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Trail</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                      <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Trail</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 9.832v1.793c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875V9.832a3 3 0 00-.722-1.952l-3.285-3.832A3 3 0 0016.215 3h-8.43a3 3 0 00-2.278 1.048L2.222 7.88A3 3 0 001.5 9.832zM7.785 4.5a1.5 1.5 0 00-1.139.524L3.881 8.25h3.165a3 3 0 012.496 1.336l.164.246a1.5 1.5 0 001.248.668h2.092a1.5 1.5 0 001.248-.668l.164-.246a3 3 0 012.496-1.336h3.165l-2.765-3.226a1.5 1.5 0 00-1.139-.524h-8.43z"
                        clip-rule="evenodd"
                      />
                      <path d="M2.813 15c-.725 0-1.313.588-1.313 1.313V18a3 3 0 003 3h15a3 3 0 003-3v-1.688c0-.724-.588-1.312-1.313-1.312h-4.233a3 3 0 00-2.496 1.336l-.164.246a1.5 1.5 0 01-1.248.668h-2.092a1.5 1.5 0 01-1.248-.668l-.164-.246A3 3 0 007.046 15H2.812z" />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Badge</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    <span className="absolute -top-2 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                      9<span className="sr-only"> new emails</span>
                    </span>
                  </span>
                </button>
              </div>
              {/*<!-- End Large sized primary button group with trailing icon and badge --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedBasePrimaryTrailingIcon}
              componentName="ButtonGroupsRoundedBasePrimaryTrailingIcon"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Base sized primary button group with trailing icon and badge --> */}
              <div className="inline-flex divide-x divide-emerald-100 overflow-hidden rounded">
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Trail</span>
                  <span className="relative only:-mx-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                      <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Trail</span>
                  <span className="relative only:-mx-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 9.832v1.793c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875V9.832a3 3 0 00-.722-1.952l-3.285-3.832A3 3 0 0016.215 3h-8.43a3 3 0 00-2.278 1.048L2.222 7.88A3 3 0 001.5 9.832zM7.785 4.5a1.5 1.5 0 00-1.139.524L3.881 8.25h3.165a3 3 0 012.496 1.336l.164.246a1.5 1.5 0 001.248.668h2.092a1.5 1.5 0 001.248-.668l.164-.246a3 3 0 012.496-1.336h3.165l-2.765-3.226a1.5 1.5 0 00-1.139-.524h-8.43z"
                        clip-rule="evenodd"
                      />
                      <path d="M2.813 15c-.725 0-1.313.588-1.313 1.313V18a3 3 0 003 3h15a3 3 0 003-3v-1.688c0-.724-.588-1.312-1.313-1.312h-4.233a3 3 0 00-2.496 1.336l-.164.246a1.5 1.5 0 01-1.248.668h-2.092a1.5 1.5 0 01-1.248-.668l-.164-.246A3 3 0 007.046 15H2.812z" />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Badge</span>
                  <span className="relative only:-mx-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    <span className="absolute -top-2 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                      {" "}
                      9<span className="sr-only"> new emails</span>{" "}
                    </span>
                  </span>
                </button>
              </div>
              {/*<!-- End Base sized primary button group with trailing icon and badge --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedSmPrimaryTrailingIcon}
              componentName="ButtonGroupsRoundedSmPrimaryTrailingIcon"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Sm sized primary button group with trailing icon and badge --> */}
              <div className="inline-flex divide-x divide-emerald-100 overflow-hidden rounded">
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Trail</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-4 w-4"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                      <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Trail</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-4 w-4"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 9.832v1.793c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875V9.832a3 3 0 00-.722-1.952l-3.285-3.832A3 3 0 0016.215 3h-8.43a3 3 0 00-2.278 1.048L2.222 7.88A3 3 0 001.5 9.832zM7.785 4.5a1.5 1.5 0 00-1.139.524L3.881 8.25h3.165a3 3 0 012.496 1.336l.164.246a1.5 1.5 0 001.248.668h2.092a1.5 1.5 0 001.248-.668l.164-.246a3 3 0 012.496-1.336h3.165l-2.765-3.226a1.5 1.5 0 00-1.139-.524h-8.43z"
                        clip-rule="evenodd"
                      />
                      <path d="M2.813 15c-.725 0-1.313.588-1.313 1.313V18a3 3 0 003 3h15a3 3 0 003-3v-1.688c0-.724-.588-1.312-1.313-1.312h-4.233a3 3 0 00-2.496 1.336l-.164.246a1.5 1.5 0 01-1.248.668h-2.092a1.5 1.5 0 01-1.248-.668l-.164-.246A3 3 0 007.046 15H2.812z" />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Badge</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    <span className="absolute -right-0.5 -top-0.5 rounded-full bg-pink-500 p-1">
                      <span className="sr-only">8 new emails</span>
                    </span>
                  </span>
                </button>
              </div>
              {/*<!-- End Sm sized primary button group with trailing icon and badge --> */}
            </CopyComponent>
          </div>
        </div>
        <div className="inline-flex flex-col gap-8">
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedLgPrimaryDisabled}
              componentName="ButtonGroupsRoundedLgPrimaryDisabled"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Large sized primary disabled button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Large</span>
                </button>
                <button
                  className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                  disabled
                >
                  <span>Disabled</span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Large</span>
                </button>
              </div>
              {/*<!-- End Large sized primary disabled button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedBasePrimaryDisabled}
              componentName="ButtonGroupsRoundedBasePrimaryDisabled"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Base sized primary disabled button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Base</span>
                </button>
                <button
                  className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                  disabled
                >
                  <span>Disabled</span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Base</span>
                </button>
              </div>
              {/*<!-- End Base sized primary disabled button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedSmPrimaryDisabled}
              componentName="ButtonGroupsRoundedSmPrimaryDisabled"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Sm sized primary disabled button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Small</span>
                </button>
                <button
                  className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                  disabled
                >
                  <span>Disabled</span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span>Small</span>
                </button>
              </div>
              {/*<!-- End Sm sized primary disabled button group --> */}
            </CopyComponent>
          </div>
        </div>
        <div className="inline-flex flex-col gap-8">
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedLgPrimaryIcon}
              componentName="ButtonGroupsRoundedLgPrimaryIcon"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Large sized primary icon button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-12 items-center justify-center gap-2 self-center whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="relative only:-mx-6">
                    <span className="sr-only">Button description</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z"
                      />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 self-center whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="relative only:-mx-6">
                    <span className="sr-only">Button description</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 010 1.954l-7.108 4.061A1.125 1.125 0 013 16.811z"
                      />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 self-center whitespace-nowrap bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="relative only:-mx-6">
                    <span className="sr-only">Button description</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              {/*<!-- End Large sized primary icon button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedBasePrimaryIcon}
              componentName="ButtonGroupsRoundedBasePrimaryIcon"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Base sized primary icon button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-10 items-center justify-center gap-2 self-center whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="relative only:-mx-5">
                    <span className="sr-only">Button description</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z"
                      />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 self-center whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="relative only:-mx-5">
                    <span className="sr-only">Button description</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 010 1.954l-7.108 4.061A1.125 1.125 0 013 16.811z"
                      />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 self-center whitespace-nowrap bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="relative only:-mx-5">
                    <span className="sr-only">Button description</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              {/*<!-- End Base sized primary icon button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedSmPrimaryIcon}
              componentName="ButtonGroupsRoundedSmPrimaryIcon"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Sm sized primary icon button group --> */}
              <div className="inline-flex overflow-hidden rounded">
                <button className="inline-flex h-8 items-center justify-center gap-2 self-center whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="relative only:-mx-4">
                    <span className="sr-only">Button description</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-4 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z"
                      />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 self-center whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="relative only:-mx-4">
                    <span className="sr-only">Button description</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-4 w-4"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 010 1.954l-7.108 4.061A1.125 1.125 0 013 16.811z"
                      />
                    </svg>
                  </span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 self-center whitespace-nowrap bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                  <span className="relative only:-mx-4">
                    <span className="sr-only">Button description</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-4 w-4"
                      aria-label="Button icon"
                      role="graphics-symbol"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              {/*<!-- End Sm sized primary icon button group --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
