import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ButtonGroupsAllOutlinePreview(props) {
  const {
    ButtonGroupsRoundedLgOutlineBasic,
    ButtonGroupsRoundedBaseOutlineBasic,
    ButtonGroupsRoundedSmOutlineBasic,
  } = props

  return (
    <>
      <div className="flex w-full flex-col gap-20 md:w-auto">
        <div className="inline-flex flex-col gap-8">
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedLgOutlineBasic}
              componentName="ButtonGroupsRoundedLgOutlineBasic"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Large sized basic outline button group --> */}
              <div className="inline-flex divide-x divide-emerald-500 overflow-hidden rounded border border-emerald-500 disabled:border-emerald-300">
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
                  <span>Large</span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
                  <span>Large</span>
                </button>
                <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
                  <span>Large</span>
                </button>
              </div>
              {/*<!-- End Large sized basic outline button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedBaseOutlineBasic}
              componentName="ButtonGroupsRoundedBaseOutlineBasic"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Base sized basic outline button group --> */}
              <div className="inline-flex divide-x divide-emerald-500 overflow-hidden rounded border border-emerald-500 disabled:border-emerald-300">
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
                  <span>Base</span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
                  <span>Base</span>
                </button>
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
                  <span>Base</span>
                </button>
              </div>
              {/*<!-- End Base sized basic outline button group --> */}
            </CopyComponent>
          </div>
          <div>
            <CopyComponent
              copyToClipboardCode={ButtonGroupsRoundedSmOutlineBasic}
              componentName="ButtonGroupsRoundedSmOutlineBasic"
              extraClasses="inline-flex"
            >
              {/*<!-- Component: Sm sized basic outline button group --> */}
              <div className="inline-flex divide-x divide-emerald-500 overflow-hidden rounded border border-emerald-500 disabled:border-emerald-300">
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
                  <span>Small</span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
                  <span>Small</span>
                </button>
                <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none">
                  <span>Small</span>
                </button>
              </div>
              {/*<!-- End Sm sized basic outline button group --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
