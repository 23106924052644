import React, { useState } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// SEO Image
import ogImage from "../../../images/seo/button-groups/button-groups.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import HeroButtonGroupComponent from "../../../library/components/button-groups/react/_preview/heroButtonGroupComponent"
import ButtonGroupsAllPrimaryPreview from "../../../library/components/button-groups/react/_preview/primary"
import ButtonGroupsAllSecondaryPreview from "../../../library/components/button-groups/react/_preview/secondary"
import ButtonGroupsAllPrimaryRoundedPreview from "../../../library/components/button-groups/react/_preview/primary-rounded"
import ButtonGroupsAllSecondaryRoundedPreview from "../../../library/components/button-groups/react/_preview/secondary-rounded"
import ButtonGroupsAllOutlinePreview from "../../../library/components/button-groups/react/_preview/outline"
import ButtonGroupsAllLinkPreview from "../../../library/components/button-groups/react/_preview/link"

// All Rounded
// Primary
// Large
const ButtonGroupsRoundedLgPrimaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/primary-basic.jsx")
const ButtonGroupsRoundedLgPrimaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/primary-basic.html")

const ButtonGroupsRoundedLgPrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/primary-elevated.jsx")
const ButtonGroupsRoundedLgPrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/primary-elevated.html")

const ButtonGroupsRoundedLgPrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/primary-leading_icon.jsx")
const ButtonGroupsRoundedLgPrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/primary-leading_icon.html")

const ButtonGroupsRoundedLgPrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/primary-trailing_icon.jsx")
const ButtonGroupsRoundedLgPrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/primary-trailing_icon.html")

const ButtonGroupsRoundedLgPrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/primary-disabled.jsx")
const ButtonGroupsRoundedLgPrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/primary-disabled.html")

const ButtonGroupsRoundedLgPrimaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/primary-icon.jsx")
const ButtonGroupsRoundedLgPrimaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/primary-icon.html")

// Base
const ButtonGroupsRoundedBasePrimaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/primary-basic.jsx")
const ButtonGroupsRoundedBasePrimaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/primary-basic.html")

const ButtonGroupsRoundedBasePrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/primary-elevated.jsx")
const ButtonGroupsRoundedBasePrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/primary-elevated.html")

const ButtonGroupsRoundedBasePrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/primary-leading_icon.jsx")
const ButtonGroupsRoundedBasePrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/primary-leading_icon.html")

const ButtonGroupsRoundedBasePrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/primary-trailing_icon.jsx")
const ButtonGroupsRoundedBasePrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/primary-trailing_icon.html")

const ButtonGroupsRoundedBasePrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/primary-disabled.jsx")
const ButtonGroupsRoundedBasePrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/primary-disabled.html")

const ButtonGroupsRoundedBasePrimaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/primary-icon.jsx")
const ButtonGroupsRoundedBasePrimaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/primary-icon.html")

// Sm
const ButtonGroupsRoundedSmPrimaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/primary-basic.jsx")
const ButtonGroupsRoundedSmPrimaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/primary-basic.html")

const ButtonGroupsRoundedSmPrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/primary-elevated.jsx")
const ButtonGroupsRoundedSmPrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/primary-elevated.html")

const ButtonGroupsRoundedSmPrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/primary-leading_icon.jsx")
const ButtonGroupsRoundedSmPrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/primary-leading_icon.html")

const ButtonGroupsRoundedSmPrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/primary-trailing_icon.jsx")
const ButtonGroupsRoundedSmPrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/primary-trailing_icon.html")

const ButtonGroupsRoundedSmPrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/primary-disabled.jsx")
const ButtonGroupsRoundedSmPrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/primary-disabled.html")

const ButtonGroupsRoundedSmPrimaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/primary-icon.jsx")
const ButtonGroupsRoundedSmPrimaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/primary-icon.html")

// Secondary
// Large
const ButtonGroupsRoundedLgSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/secondary-basic.jsx")
const ButtonGroupsRoundedLgSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/secondary-basic.html")

const ButtonGroupsRoundedLgSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/secondary-elevated.jsx")
const ButtonGroupsRoundedLgSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/secondary-elevated.html")

const ButtonGroupsRoundedLgSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/secondary-leading_icon.jsx")
const ButtonGroupsRoundedLgSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/secondary-leading_icon.html")

const ButtonGroupsRoundedLgSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/secondary-trailing_icon.jsx")
const ButtonGroupsRoundedLgSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/secondary-trailing_icon.html")

const ButtonGroupsRoundedLgSecondaryActiveJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/secondary-active.jsx")
const ButtonGroupsRoundedLgSecondaryActiveHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/secondary-active.html")

const ButtonGroupsRoundedLgSecondaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/secondary-icon.jsx")
const ButtonGroupsRoundedLgSecondaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/secondary-icon.html")

// Base
const ButtonGroupsRoundedBaseSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/secondary-basic.jsx")
const ButtonGroupsRoundedBaseSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/secondary-basic.html")

const ButtonGroupsRoundedBaseSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/secondary-elevated.jsx")
const ButtonGroupsRoundedBaseSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/secondary-elevated.html")

const ButtonGroupsRoundedBaseSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/secondary-leading_icon.jsx")
const ButtonGroupsRoundedBaseSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/secondary-leading_icon.html")

const ButtonGroupsRoundedBaseSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/secondary-trailing_icon.jsx")
const ButtonGroupsRoundedBaseSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/secondary-trailing_icon.html")

const ButtonGroupsRoundedBaseSecondaryActiveJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/secondary-active.jsx")
const ButtonGroupsRoundedBaseSecondaryActiveHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/secondary-active.html")

const ButtonGroupsRoundedBaseSecondaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/secondary-icon.jsx")
const ButtonGroupsRoundedBaseSecondaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/secondary-icon.html")

// Sm
const ButtonGroupsRoundedSmSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/secondary-basic.jsx")
const ButtonGroupsRoundedSmSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/secondary-basic.html")

const ButtonGroupsRoundedSmSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/secondary-elevated.jsx")
const ButtonGroupsRoundedSmSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/secondary-elevated.html")

const ButtonGroupsRoundedSmSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/secondary-leading_icon.jsx")
const ButtonGroupsRoundedSmSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/secondary-leading_icon.html")

const ButtonGroupsRoundedSmSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/secondary-trailing_icon.jsx")
const ButtonGroupsRoundedSmSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/secondary-trailing_icon.html")

const ButtonGroupsRoundedSmSecondaryActiveJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/secondary-active.jsx")
const ButtonGroupsRoundedSmSecondaryActiveHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/secondary-active.html")

const ButtonGroupsRoundedSmSecondaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/secondary-icon.jsx")
const ButtonGroupsRoundedSmSecondaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/secondary-icon.html")

// All Rounded full
// Primary
// Large
const ButtonGroupsRoundedFullLgPrimaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/primary-basic.jsx")
const ButtonGroupsRoundedFullLgPrimaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/primary-basic.html")

const ButtonGroupsRoundedFullLgPrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/primary-elevated.jsx")
const ButtonGroupsRoundedFullLgPrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/primary-elevated.html")

const ButtonGroupsRoundedFullLgPrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/primary-leading_icon.jsx")
const ButtonGroupsRoundedFullLgPrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/primary-leading_icon.html")

const ButtonGroupsRoundedFullLgPrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/primary-trailing_icon.jsx")
const ButtonGroupsRoundedFullLgPrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/primary-trailing_icon.html")

const ButtonGroupsRoundedFullLgPrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/primary-disabled.jsx")
const ButtonGroupsRoundedFullLgPrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/primary-disabled.html")

const ButtonGroupsRoundedFullLgPrimaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/primary-icon.jsx")
const ButtonGroupsRoundedFullLgPrimaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/primary-icon.html")

// Base
const ButtonGroupsRoundedFullBasePrimaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/primary-basic.jsx")
const ButtonGroupsRoundedFullBasePrimaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/primary-basic.html")

const ButtonGroupsRoundedFullBasePrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/primary-elevated.jsx")
const ButtonGroupsRoundedFullBasePrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/primary-elevated.html")

const ButtonGroupsRoundedFullBasePrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/primary-leading_icon.jsx")
const ButtonGroupsRoundedFullBasePrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/primary-leading_icon.html")

const ButtonGroupsRoundedFullBasePrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/primary-trailing_icon.jsx")
const ButtonGroupsRoundedFullBasePrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/primary-trailing_icon.html")

const ButtonGroupsRoundedFullBasePrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/primary-disabled.jsx")
const ButtonGroupsRoundedFullBasePrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/primary-disabled.html")

const ButtonGroupsRoundedFullBasePrimaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/primary-icon.jsx")
const ButtonGroupsRoundedFullBasePrimaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/primary-icon.html")

// Sm
const ButtonGroupsRoundedFullSmPrimaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/primary-basic.jsx")
const ButtonGroupsRoundedFullSmPrimaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/primary-basic.html")

const ButtonGroupsRoundedFullSmPrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/primary-elevated.jsx")
const ButtonGroupsRoundedFullSmPrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/primary-elevated.html")

const ButtonGroupsRoundedFullSmPrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/primary-leading_icon.jsx")
const ButtonGroupsRoundedFullSmPrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/primary-leading_icon.html")

const ButtonGroupsRoundedFullSmPrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/primary-trailing_icon.jsx")
const ButtonGroupsRoundedFullSmPrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/primary-trailing_icon.html")

const ButtonGroupsRoundedFullSmPrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/primary-disabled.jsx")
const ButtonGroupsRoundedFullSmPrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/primary-disabled.html")

const ButtonGroupsRoundedFullSmPrimaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/primary-icon.jsx")
const ButtonGroupsRoundedFullSmPrimaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/primary-icon.html")

// Secondary
// Large
const ButtonGroupsRoundedFullLgSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/secondary-basic.jsx")
const ButtonGroupsRoundedFullLgSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/secondary-basic.html")

const ButtonGroupsRoundedFullLgSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/secondary-elevated.jsx")
const ButtonGroupsRoundedFullLgSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/secondary-elevated.html")

const ButtonGroupsRoundedFullLgSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/secondary-leading_icon.jsx")
const ButtonGroupsRoundedFullLgSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/secondary-leading_icon.html")

const ButtonGroupsRoundedFullLgSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/secondary-trailing_icon.jsx")
const ButtonGroupsRoundedFullLgSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/secondary-trailing_icon.html")

const ButtonGroupsRoundedFullLgSecondaryActiveJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/secondary-active.jsx")
const ButtonGroupsRoundedFullLgSecondaryActiveHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/secondary-active.html")

const ButtonGroupsRoundedFullLgSecondaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/lg/secondary-icon.jsx")
const ButtonGroupsRoundedFullLgSecondaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/lg/secondary-icon.html")

// Base
const ButtonGroupsRoundedFullBaseSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/secondary-basic.jsx")
const ButtonGroupsRoundedFullBaseSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/secondary-basic.html")

const ButtonGroupsRoundedFullBaseSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/secondary-elevated.jsx")
const ButtonGroupsRoundedFullBaseSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/secondary-elevated.html")

const ButtonGroupsRoundedFullBaseSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/secondary-leading_icon.jsx")
const ButtonGroupsRoundedFullBaseSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/secondary-leading_icon.html")

const ButtonGroupsRoundedFullBaseSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/secondary-trailing_icon.jsx")
const ButtonGroupsRoundedFullBaseSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/secondary-trailing_icon.html")

const ButtonGroupsRoundedFullBaseSecondaryActiveJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/secondary-active.jsx")
const ButtonGroupsRoundedFullBaseSecondaryActiveHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/secondary-active.html")

const ButtonGroupsRoundedFullBaseSecondaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/base/secondary-icon.jsx")
const ButtonGroupsRoundedFullBaseSecondaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/base/secondary-icon.html")

// Sm
const ButtonGroupsRoundedFullSmSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/secondary-basic.jsx")
const ButtonGroupsRoundedFullSmSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/secondary-basic.html")

const ButtonGroupsRoundedFullSmSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/secondary-elevated.jsx")
const ButtonGroupsRoundedFullSmSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/secondary-elevated.html")

const ButtonGroupsRoundedFullSmSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/secondary-leading_icon.jsx")
const ButtonGroupsRoundedFullSmSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/secondary-leading_icon.html")

const ButtonGroupsRoundedFullSmSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/secondary-trailing_icon.jsx")
const ButtonGroupsRoundedFullSmSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/secondary-trailing_icon.html")

const ButtonGroupsRoundedFullSmSecondaryActiveJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/secondary-active.jsx")
const ButtonGroupsRoundedFullSmSecondaryActiveHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/secondary-active.html")

const ButtonGroupsRoundedFullSmSecondaryIconJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded-full/sm/secondary-icon.jsx")
const ButtonGroupsRoundedFullSmSecondaryIconHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded-full/sm/secondary-icon.html")

// Outline
// Large
const ButtonGroupsRoundedLgOutlineBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/outline-basic.jsx")
const ButtonGroupsRoundedLgOutlineBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/outline-basic.html")

// Base
const ButtonGroupsRoundedBaseOutlineBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/outline-basic.jsx")
const ButtonGroupsRoundedBaseOutlineBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/outline-basic.html")

// Sm
const ButtonGroupsRoundedSmOutlineBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/outline-basic.jsx")
const ButtonGroupsRoundedSmOutlineBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/outline-basic.html")

// Link
// Large
const ButtonGroupsRoundedLgLinkBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/lg/link-basic.jsx")
const ButtonGroupsRoundedLgLinkBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/lg/link-basic.html")

// Base
const ButtonGroupsRoundedBaseLinkBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/base/link-basic.jsx")
const ButtonGroupsRoundedBaseLinkBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/base/link-basic.html")

// Sm
const ButtonGroupsRoundedSmLinkBasicJsx = require("!!raw-loader!../../../library/components/button-groups/react/rounded/sm/link-basic.jsx")
const ButtonGroupsRoundedSmLinkBasicHTML = require("!!raw-loader!../../../library/components/button-groups/html/rounded/sm/link-basic.html")

export default function ButtonGroupsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "ButtonGroupPrimary",
      title: "Primary button groups",
      active_tab: 1,
    },
    {
      component_name: "ButtonGroupSecondary",
      title: "Secondary",
      active_tab: 1,
    },
    {
      component_name: "ButtonGroupRoundedFullPrimary",
      title: "Rounded full primary",
      active_tab: 1,
    },
    {
      component_name: "ButtonGroupRoundedFullSecondary",
      title: "Rounded full secondary",
      active_tab: 1,
    },
    {
      component_name: "ButtonGroupOutline",
      title: "Outline",
      active_tab: 1,
    },
    {
      component_name: "ButtonGroupLink",
      title: "Link",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Button Groups - WindUI Component Library"
        ogtitle="Tailwind CSS Button Groups - WindUI Component Library"
        description="Button group components help people select options, switch views, or sort elements. Built with Tailwind CSS by WindUI."
        ogdescription="Button group components help people select options, switch views, or sort elements. Built with Tailwind CSS by WindUI."
        url="components/button-groups/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Button Groups, Button Groups, Button Group Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Button Groups</h1>
          <p>
            Button groups help people select options, switch views, or sort
            elements.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 px-0 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:py-16 [&>*]:w-full">
              <HeroButtonGroupComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="ButtonGroupPrimary">Primary button groups</h3>

          <PreviewBlock
            id="ButtonGroupPrimary"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonGroupsRoundedLgPrimaryBasicHTML.default}
            JsxComponent={ButtonGroupsRoundedLgPrimaryBasicJsx.default}
          >
            <div className="flex w-full items-center justify-center overflow-scroll rounded-xl bg-white px-8 py-16 md:overflow-hidden">
              <ButtonGroupsAllPrimaryPreview
                ButtonGroupsRoundedLgPrimaryBasic={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedLgPrimaryBasicHTML.default
                    : ButtonGroupsRoundedLgPrimaryBasicJsx.default
                }
                ButtonGroupsRoundedBasePrimaryBasic={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedBasePrimaryBasicHTML.default
                    : ButtonGroupsRoundedBasePrimaryBasicJsx.default
                }
                ButtonGroupsRoundedSmPrimaryBasic={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedSmPrimaryBasicHTML.default
                    : ButtonGroupsRoundedSmPrimaryBasicJsx.default
                }
                ButtonGroupsRoundedLgPrimaryElevated={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedLgPrimaryElevatedHTML.default
                    : ButtonGroupsRoundedLgPrimaryElevatedJsx.default
                }
                ButtonGroupsRoundedBasePrimaryElevated={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedBasePrimaryElevatedHTML.default
                    : ButtonGroupsRoundedBasePrimaryElevatedJsx.default
                }
                ButtonGroupsRoundedSmPrimaryElevated={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedSmPrimaryElevatedHTML.default
                    : ButtonGroupsRoundedSmPrimaryElevatedJsx.default
                }
                ButtonGroupsRoundedLgPrimaryLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedLgPrimaryLeadingIconHTML.default
                    : ButtonGroupsRoundedLgPrimaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedBasePrimaryLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedBasePrimaryLeadingIconHTML.default
                    : ButtonGroupsRoundedBasePrimaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedSmPrimaryLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedSmPrimaryLeadingIconHTML.default
                    : ButtonGroupsRoundedSmPrimaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedLgPrimaryTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedLgPrimaryTrailingIconHTML.default
                    : ButtonGroupsRoundedLgPrimaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedBasePrimaryTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedBasePrimaryTrailingIconHTML.default
                    : ButtonGroupsRoundedBasePrimaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedSmPrimaryTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedSmPrimaryTrailingIconHTML.default
                    : ButtonGroupsRoundedSmPrimaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedLgPrimaryDisabled={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedLgPrimaryDisabledHTML.default
                    : ButtonGroupsRoundedLgPrimaryDisabledJsx.default
                }
                ButtonGroupsRoundedBasePrimaryDisabled={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedBasePrimaryDisabledHTML.default
                    : ButtonGroupsRoundedBasePrimaryDisabledJsx.default
                }
                ButtonGroupsRoundedSmPrimaryDisabled={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedSmPrimaryDisabledHTML.default
                    : ButtonGroupsRoundedSmPrimaryDisabledJsx.default
                }
                ButtonGroupsRoundedLgPrimaryIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedLgPrimaryIconHTML.default
                    : ButtonGroupsRoundedLgPrimaryIconJsx.default
                }
                ButtonGroupsRoundedBasePrimaryIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedBasePrimaryIconHTML.default
                    : ButtonGroupsRoundedBasePrimaryIconJsx.default
                }
                ButtonGroupsRoundedSmPrimaryIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonGroupsRoundedSmPrimaryIconHTML.default
                    : ButtonGroupsRoundedSmPrimaryIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ButtonGroupSecondary">Secondary button groups</h3>

          <PreviewBlock
            id="ButtonGroupSecondary"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonGroupsRoundedLgSecondaryBasicHTML.default}
            JsxComponent={ButtonGroupsRoundedLgSecondaryBasicJsx.default}
          >
            <div className="flex w-full items-center justify-center overflow-scroll rounded-xl bg-white px-8 py-16 md:overflow-hidden">
              <ButtonGroupsAllSecondaryPreview
                ButtonGroupsRoundedLgSecondaryBasic={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedLgSecondaryBasicHTML.default
                    : ButtonGroupsRoundedLgSecondaryBasicJsx.default
                }
                ButtonGroupsRoundedBaseSecondaryBasic={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedBaseSecondaryBasicHTML.default
                    : ButtonGroupsRoundedBaseSecondaryBasicJsx.default
                }
                ButtonGroupsRoundedSmSecondaryBasic={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedSmSecondaryBasicHTML.default
                    : ButtonGroupsRoundedSmSecondaryBasicJsx.default
                }
                ButtonGroupsRoundedLgSecondaryElevated={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedLgSecondaryElevatedHTML.default
                    : ButtonGroupsRoundedLgSecondaryElevatedJsx.default
                }
                ButtonGroupsRoundedBaseSecondaryElevated={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedBaseSecondaryElevatedHTML.default
                    : ButtonGroupsRoundedBaseSecondaryElevatedJsx.default
                }
                ButtonGroupsRoundedSmSecondaryElevated={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedSmSecondaryElevatedHTML.default
                    : ButtonGroupsRoundedSmSecondaryElevatedJsx.default
                }
                ButtonGroupsRoundedLgSecondaryLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedLgSecondaryLeadingIconHTML.default
                    : ButtonGroupsRoundedLgSecondaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedBaseSecondaryLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedBaseSecondaryLeadingIconHTML.default
                    : ButtonGroupsRoundedBaseSecondaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedSmSecondaryLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedSmSecondaryLeadingIconHTML.default
                    : ButtonGroupsRoundedSmSecondaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedLgSecondaryTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedLgSecondaryTrailingIconHTML.default
                    : ButtonGroupsRoundedLgSecondaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedBaseSecondaryTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedBaseSecondaryTrailingIconHTML.default
                    : ButtonGroupsRoundedBaseSecondaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedSmSecondaryTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedSmSecondaryTrailingIconHTML.default
                    : ButtonGroupsRoundedSmSecondaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedLgSecondaryActive={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedLgSecondaryActiveHTML.default
                    : ButtonGroupsRoundedLgSecondaryActiveJsx.default
                }
                ButtonGroupsRoundedBaseSecondaryActive={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedBaseSecondaryActiveHTML.default
                    : ButtonGroupsRoundedBaseSecondaryActiveJsx.default
                }
                ButtonGroupsRoundedSmSecondaryActive={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedSmSecondaryActiveHTML.default
                    : ButtonGroupsRoundedSmSecondaryActiveJsx.default
                }
                ButtonGroupsRoundedLgSecondaryIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedLgSecondaryIconHTML.default
                    : ButtonGroupsRoundedLgSecondaryIconJsx.default
                }
                ButtonGroupsRoundedBaseSecondaryIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedBaseSecondaryIconHTML.default
                    : ButtonGroupsRoundedBaseSecondaryIconJsx.default
                }
                ButtonGroupsRoundedSmSecondaryIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonGroupsRoundedSmSecondaryIconHTML.default
                    : ButtonGroupsRoundedSmSecondaryIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ButtonGroupRoundedFullPrimary">
            Rounded full primary button groups
          </h3>

          <PreviewBlock
            id="ButtonGroupRoundedFullPrimary"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonGroupsRoundedLgPrimaryBasicHTML.default}
            JsxComponent={ButtonGroupsRoundedLgPrimaryBasicJsx.default}
          >
            <div className="flex w-full items-center justify-center overflow-scroll rounded-xl bg-white px-8 py-16 md:overflow-hidden">
              <ButtonGroupsAllPrimaryRoundedPreview
                ButtonGroupsRoundedFullLgPrimaryBasic={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullLgPrimaryBasicHTML.default
                    : ButtonGroupsRoundedFullLgPrimaryBasicJsx.default
                }
                ButtonGroupsRoundedFullBasePrimaryBasic={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullBasePrimaryBasicHTML.default
                    : ButtonGroupsRoundedFullBasePrimaryBasicJsx.default
                }
                ButtonGroupsRoundedFullSmPrimaryBasic={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullSmPrimaryBasicHTML.default
                    : ButtonGroupsRoundedFullSmPrimaryBasicJsx.default
                }
                ButtonGroupsRoundedFullLgPrimaryElevated={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullLgPrimaryElevatedHTML.default
                    : ButtonGroupsRoundedFullLgPrimaryElevatedJsx.default
                }
                ButtonGroupsRoundedFullBasePrimaryElevated={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullBasePrimaryElevatedHTML.default
                    : ButtonGroupsRoundedFullBasePrimaryElevatedJsx.default
                }
                ButtonGroupsRoundedFullSmPrimaryElevated={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullSmPrimaryElevatedHTML.default
                    : ButtonGroupsRoundedFullSmPrimaryElevatedJsx.default
                }
                ButtonGroupsRoundedFullLgPrimaryLeadingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullLgPrimaryLeadingIconHTML.default
                    : ButtonGroupsRoundedFullLgPrimaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedFullBasePrimaryLeadingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullBasePrimaryLeadingIconHTML.default
                    : ButtonGroupsRoundedFullBasePrimaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedFullSmPrimaryLeadingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullSmPrimaryLeadingIconHTML.default
                    : ButtonGroupsRoundedFullSmPrimaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedFullLgPrimaryTrailingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullLgPrimaryTrailingIconHTML.default
                    : ButtonGroupsRoundedFullLgPrimaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedFullBasePrimaryTrailingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullBasePrimaryTrailingIconHTML.default
                    : ButtonGroupsRoundedFullBasePrimaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedFullSmPrimaryTrailingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullSmPrimaryTrailingIconHTML.default
                    : ButtonGroupsRoundedFullSmPrimaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedFullLgPrimaryDisabled={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullLgPrimaryDisabledHTML.default
                    : ButtonGroupsRoundedFullLgPrimaryDisabledJsx.default
                }
                ButtonGroupsRoundedFullBasePrimaryDisabled={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullBasePrimaryDisabledHTML.default
                    : ButtonGroupsRoundedFullBasePrimaryDisabledJsx.default
                }
                ButtonGroupsRoundedFullSmPrimaryDisabled={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullSmPrimaryDisabledHTML.default
                    : ButtonGroupsRoundedFullSmPrimaryDisabledJsx.default
                }
                ButtonGroupsRoundedFullLgPrimaryIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullLgPrimaryIconHTML.default
                    : ButtonGroupsRoundedFullLgPrimaryIconJsx.default
                }
                ButtonGroupsRoundedFullBasePrimaryIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullBasePrimaryIconHTML.default
                    : ButtonGroupsRoundedFullBasePrimaryIconJsx.default
                }
                ButtonGroupsRoundedFullSmPrimaryIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonGroupsRoundedFullSmPrimaryIconHTML.default
                    : ButtonGroupsRoundedFullSmPrimaryIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ButtonGroupRoundedFullSecondary">
            Rounded full secondary button groups
          </h3>

          <PreviewBlock
            id="ButtonGroupRoundedFullSecondary"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonGroupsRoundedLgPrimaryBasicHTML.default}
            JsxComponent={ButtonGroupsRoundedLgPrimaryBasicJsx.default}
          >
            <div className="flex w-full items-center justify-center overflow-scroll rounded-xl bg-white px-8 py-16 md:overflow-hidden">
              <ButtonGroupsAllSecondaryRoundedPreview
                ButtonGroupsRoundedFullLgSecondaryBasic={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullLgSecondaryBasicHTML.default
                    : ButtonGroupsRoundedFullLgSecondaryBasicJsx.default
                }
                ButtonGroupsRoundedFullBaseSecondaryBasic={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullBaseSecondaryBasicHTML.default
                    : ButtonGroupsRoundedFullBaseSecondaryBasicJsx.default
                }
                ButtonGroupsRoundedFullSmSecondaryBasic={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullSmSecondaryBasicHTML.default
                    : ButtonGroupsRoundedFullSmSecondaryBasicJsx.default
                }
                ButtonGroupsRoundedFullLgSecondaryElevated={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullLgSecondaryElevatedHTML.default
                    : ButtonGroupsRoundedFullLgSecondaryElevatedJsx.default
                }
                ButtonGroupsRoundedFullBaseSecondaryElevated={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullBaseSecondaryElevatedHTML.default
                    : ButtonGroupsRoundedFullBaseSecondaryElevatedJsx.default
                }
                ButtonGroupsRoundedFullSmSecondaryElevated={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullSmSecondaryElevatedHTML.default
                    : ButtonGroupsRoundedFullSmSecondaryElevatedJsx.default
                }
                ButtonGroupsRoundedFullLgSecondaryLeadingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullLgSecondaryLeadingIconHTML.default
                    : ButtonGroupsRoundedFullLgSecondaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedFullBaseSecondaryLeadingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullBaseSecondaryLeadingIconHTML.default
                    : ButtonGroupsRoundedFullBaseSecondaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedFullSmSecondaryLeadingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullSmSecondaryLeadingIconHTML.default
                    : ButtonGroupsRoundedFullSmSecondaryLeadingIconJsx.default
                }
                ButtonGroupsRoundedFullLgSecondaryTrailingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullLgSecondaryTrailingIconHTML.default
                    : ButtonGroupsRoundedFullLgSecondaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedFullBaseSecondaryTrailingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullBaseSecondaryTrailingIconHTML.default
                    : ButtonGroupsRoundedFullBaseSecondaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedFullSmSecondaryTrailingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullSmSecondaryTrailingIconHTML.default
                    : ButtonGroupsRoundedFullSmSecondaryTrailingIconJsx.default
                }
                ButtonGroupsRoundedFullLgSecondaryActive={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullLgSecondaryActiveHTML.default
                    : ButtonGroupsRoundedFullLgSecondaryActiveJsx.default
                }
                ButtonGroupsRoundedFullBaseSecondaryActive={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullBaseSecondaryActiveHTML.default
                    : ButtonGroupsRoundedFullBaseSecondaryActiveJsx.default
                }
                ButtonGroupsRoundedFullSmSecondaryActive={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullSmSecondaryActiveHTML.default
                    : ButtonGroupsRoundedFullSmSecondaryActiveJsx.default
                }
                ButtonGroupsRoundedFullLgSecondaryIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullLgSecondaryIconHTML.default
                    : ButtonGroupsRoundedFullLgSecondaryIconJsx.default
                }
                ButtonGroupsRoundedFullBaseSecondaryIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullBaseSecondaryIconHTML.default
                    : ButtonGroupsRoundedFullBaseSecondaryIconJsx.default
                }
                ButtonGroupsRoundedFullSmSecondaryIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonGroupsRoundedFullSmSecondaryIconHTML.default
                    : ButtonGroupsRoundedFullSmSecondaryIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ButtonGroupOutline">Outline button groups</h3>

          <PreviewBlock
            id="ButtonGroupOutline"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonGroupsRoundedLgPrimaryBasicHTML.default}
            JsxComponent={ButtonGroupsRoundedLgPrimaryBasicJsx.default}
          >
            <div className="flex w-full items-center justify-center overflow-scroll rounded-xl bg-white px-8 py-16 md:overflow-hidden">
              <ButtonGroupsAllOutlinePreview
                ButtonGroupsRoundedLgOutlineBasic={
                  activeTabs[4].active_tab === 1
                    ? ButtonGroupsRoundedLgOutlineBasicHTML.default
                    : ButtonGroupsRoundedLgOutlineBasicJsx.default
                }
                ButtonGroupsRoundedBaseOutlineBasic={
                  activeTabs[4].active_tab === 1
                    ? ButtonGroupsRoundedBaseOutlineBasicHTML.default
                    : ButtonGroupsRoundedBaseOutlineBasicJsx.default
                }
                ButtonGroupsRoundedSmOutlineBasic={
                  activeTabs[4].active_tab === 1
                    ? ButtonGroupsRoundedSmOutlineBasicHTML.default
                    : ButtonGroupsRoundedSmOutlineBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ButtonGroupLink">Link button groups</h3>

          <PreviewBlock
            id="ButtonGroupLink"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonGroupsRoundedLgPrimaryBasicHTML.default}
            JsxComponent={ButtonGroupsRoundedLgPrimaryBasicJsx.default}
          >
            <div className="flex w-full items-center justify-center overflow-scroll rounded-xl bg-white px-8 py-16 md:overflow-hidden">
              <ButtonGroupsAllLinkPreview
                ButtonGroupsRoundedLgLinkBasic={
                  activeTabs[5].active_tab === 1
                    ? ButtonGroupsRoundedLgLinkBasicHTML.default
                    : ButtonGroupsRoundedLgLinkBasicJsx.default
                }
                ButtonGroupsRoundedBaseLinkBasic={
                  activeTabs[5].active_tab === 1
                    ? ButtonGroupsRoundedBaseLinkBasicHTML.default
                    : ButtonGroupsRoundedBaseLinkBasicJsx.default
                }
                ButtonGroupsRoundedSmLinkBasic={
                  activeTabs[5].active_tab === 1
                    ? ButtonGroupsRoundedSmLinkBasicHTML.default
                    : ButtonGroupsRoundedSmLinkBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Button groups can have 2-5 segments. Each segment is clearly divided
            and contains label text, an icon, or both.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Buttons come in 6 styles (primary, secondary, primary rounded,
            secondary rounded, outline and link) They can contain text, leading
            or trailing icons and even a notification badge. All variations come
            in 3 sizes:
          </p>
          <ul>
            <li>
              <strong>Small:</strong> 2em height (32px), 1rem (16px) horizontal
              padding and 0.75rem (12px) font size. Icons on small buttons are
              1rem (16px) wide and tall and have a 0.5rem (8px) distance from
              the text.
            </li>
            <li>
              <strong>Base:</strong> 2.5em height (40px), 1.25rem (20px)
              horizontal padding and 0.875rem (14px) font size. Icons on base
              buttons are 1.25rem (20px) wide and tall and have a 0.5rem (8px)
              distance from the text.
            </li>
            <li>
              <strong>Large:</strong> 3em height (48px), 1.5rem (24px)
              horizontal padding and 0.875rem (14px) font size. Icons on large
              buttons are 1.25rem (20px) wide and tall and have a 0.5rem (8px)
              distance from the text.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              A <code>&lt;button&gt;</code> tag does not need anything special
              to work. Use <code>&lt;button&gt;</code> when you can, but it is
              possible to use other elements (such as a link) as long as you
              add&nbsp;role=&quot;button&quot;
            </li>
            <li>
              In the case of icon-only buttons include a span with
              className=&ldquo;sr-only&quot; from screen readers and hence give
              more context to the button's purpose.
            </li>
            <li>
              If it contains an icon, use aria-label to describe the icon.
            </li>
            <li>
              If a link is used instead of a button element include a{" "}
              <code>role=&quot;button&quot;</code> role.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
